.previewplayer {
	border-radius: 12px;
	overflow: hidden;
	width: 100%;
	border: none !important;
}

.OrientationButton {
	display: block;
	height: 30px;
	line-height: 30px;
	margin-bottom: 12%;
	border-radius: 60px !important;
	font-size: 15px;
}

.OrientationButton::before {
	background-color: transparent !important;
}

.OrientationButton span {
	border-radius: 60px;
}

.previewplayer {
	border: 1px solid #fff;
	border-radius: 12px;
	overflow: hidden;
}

.selecttemplate {
	cursor: pointer;
	margin-top: 2%;
}

.previewmodal {
	max-width: calc(100vw) !important;
}

.ant-modal-close-x {
	color: #fff;
	font-size: 20px;
}

.templatecard img {border-radius: 6px; font-weight: 400; text-align: left;}

.templatecard .ant-card-meta-title {color: #7D82A0;}